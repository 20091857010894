<template>
  <div>
    <b-card>
      <b-table
        :items="getFeedbackReports"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        :fields="tableColumns"
        responsive
        empty-text="No reports found"
        show-empty
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="toggleReadStatus(item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50"> {{ item.read ? 'Unread' : 'Read' }} </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import Pagination from '@/common/components/common/Table/Pagination.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'FeedbackReports',
  components: { Pagination },
  data() {
    return {
      tableColumns: [
        { key: 'feedback' },
        { key: 'userName' },
        { key: 'userEmail' },
        { key: 'sentAt', label: 'Sent Date Time' },
        { key: 'read', label: 'status', formatter: v => (v ? 'Read' : 'Unread') },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { pagination } = paginationData()
    const { successfulOperationAlert } = handleAlerts()
    return { pagination, successfulOperationAlert }
  },
  methods: {
    getFeedbackReports() {
      return this.$activities.get(`/internalops/fundraising/projects/${this.$route.params.id}/reports`).then(res => {
        const reports = res.data.data
        this.pagination.totalRows = res.data.pagination.total
        return reports || []
      }).catch(() => [])
    },
    toggleReadStatus(item) {
      return this.$activities.post(`/internalops/fundraising/projects/report/${item.id}/toggle/read`).then(res => {
        item.read = !item.read
        const message = res.data.data.read ? 'Read status is set successfully' : 'Read status is removed successfully'
        this.successfulOperationAlert(message)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
